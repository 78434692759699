const faqs = [
  {
    question: "Requisitos y documentos para viajar en avión",
    answer: (
      <div>
        <p>-  Boleto de Avión: Es fundamental tener el boleto antes de viajar, ya que es tu pase para abordar. Verifica detalles como la terminal y la hora de salida, y lleva una copia impresa o digital al aeropuerto para el check-in y abordaje.</p><br/>
        <p>- Documento de Identificación: La identificación depende del destino y sus normas. Generalmente, se requiere un pasaporte válido para viajar. Tu identificación debe estar en buen estado y tener vigencia suficiente. Algunos sitios piden un pasaporte vigente por un tiempo mínimo, verifica antes de viajar.</p><br/>
        <p>- Cumplir con requisitos de salud: Es posible que debas cumplir con requisitos de salud especiales según el destino y la situación sanitaria. Esto puede incluir vacunación antes del vuelo, completar formularios de salud, u otros . Sigue las recomendaciones de las autoridades de salud y la aerolínea..</p><br/>
        <p>- Visas y Documentos de Entrada: Si viajas a un país extranjero, debes verificar si necesitas una visa o documentos de entrada. Las visas son permisos que te autorizan a ingresar a ciertos países por un período específico y con ciertos propósitos.</p><br/>
        <p>-Control de Seguridad: Mediante el escáner de rayos X, revisan tus pertenencias en busca de objetos prohibidos o peligrosos. El control de seguridad en el aeropuerto asegura la protección a bordo. Guarda objetos afilados en maleta facturada y transporta líquidos en envases pequeños de plástico..</p><br/>
        <p>- Seguro de viaje: Aunque no es obligatorio, un seguro de viaje es altamente recomendado al volar. Cubre gastos médicos en el extranjero, ayuda en cancelaciones y problemas con vuelos, y protege contra la pérdida de equipaje.</p><br/>
        <p>- Check-in: Es necesario registrarte para confirmar tu presencia en el avión y obtener tu tarjeta de embarque, que contiene detalles del vuelo y el número de asiento. Llevar la tarjeta es esencial para abordar el avión.</p><br/>
        <p>- Equipaje adecuado: El equipaje que llevas en tu viaje debe cumplir con las políticas de la aerolínea. Esto incluye el peso, tamaño y cantidad de maletas permitidas. El equipaje facturado tiene un límite de peso permitido y suele haber costos adicionales por exceso de peso.</p><br/>
        <p>- Viajar con mascotas: Si planeas viajar con una mascota, verifica las políticas de la aerolínea y los requisitos de salud y documentación necesarios. Esto incluye certificados de salud, jaulas adecuadas y cumplimiento de regulaciones aduaneras.</p><br/>
       
    
      </div>
    ),
  },

 
];

export default faqs;
